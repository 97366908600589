import { wrapGrid } from 'animate-css-grid';

export default {
  init() {
    // JavaScript to be fired on the team page
  },
  finalize() {
    // JavaScript to be fired on the team page, after the init JS

    $('.filter-name').click(function(){
      $('.filter-items').slideToggle('slow');
      $('.filter-name').toggleClass('opened');
    });

    $('.content-inner-wrp .close-box').on('click', function(){
      $('.content-inner-wrp .close-box').removeClass('content-expanded');
    });

    const grid = document.querySelector('#category-1-content');

    wrapGrid(grid, {
      duration: 350,
      stagger: 10,
      easing: 'easeInOut',
      onStart: elements =>
        console.log(`started animation for ${elements.length} elements`),
      onEnd: elements =>
        console.log(`finished animation for ${elements.length} elements`),
    });

    const grid2 = document.querySelector('#category-2-content');

    wrapGrid(grid2, {
      duration: 350,
      stagger: 10,
      easing: 'easeInOut',
      onStart: elements =>
        console.log(`started animation for ${elements.length} elements`),
      onEnd: elements =>
        console.log(`finished animation for ${elements.length} elements`),
    });

    const grid3 = document.querySelector('#category-3-content');

    wrapGrid(grid3, {
      duration: 350,
      stagger: 10,
      easing: 'easeInOut',
      onStart: elements =>
        console.log(`started animation for ${elements.length} elements`),
      onEnd: elements =>
        console.log(`finished animation for ${elements.length} elements`),
    });

    const grid4 = document.querySelector('#category-none-content');

    wrapGrid(grid4, {
      duration: 350,
      stagger: 10,
      easing: 'easeInOut',
      onStart: elements =>
        console.log(`started animation for ${elements.length} elements`),
      onEnd: elements =>
        console.log(`finished animation for ${elements.length} elements`),
    });

    // Team Members Check if have children and Hide Heading
    console.log($('#category-1-content').children().length);
    if ( $('#category-1-content').children().length == 0 ) {
      $('#category-1-heading').css('display', 'none');
    }

    console.log($('#category-2-content').children().length);
    if ( $('#category-2-content').children().length == 0 ) {
      $('#category-2-heading').css('display', 'none');
    }

    console.log($('#category-3-content').children().length);
    if ( $('#category-3-content').children().length == 0 ) {
      $('#category-3-heading').css('display', 'none');
    }

  },
};
