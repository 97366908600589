/* eslint-disable quotes */
import "lightcase/src/js/lightcase.js";
import "select2/dist/js/select2.min.js";
import 'flickity/dist/flickity.pkgd.min.js';
import Flickity from 'flickity';
import AOS from 'aos';

var shrinkHeader = 5;

export default {
  init() {
    // JavaScript to be fired on all pages

    Flickity.defaults.dragThreshold = 3;
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    AOS.init();

    if (window.innerWidth < 1400) {
      $(document).ready(function() {
        $('.news-listing-item-wrp').slice(0, 6).show();
        if ($('.news-listing-item-wrp:hidden').length != 0) {
            $('#loadMore').show();
        }
        $('#loadMore').on('click', function(e) {
            e.preventDefault();
            $('.news-listing-item-wrp:hidden').slice(0, 6).slideDown();
            if ($('.news-listing-item-wrp:hidden').length == 0) {
                $('#loadMore').text('No More to view').fadeOut('slow');
            }
        });
      })
    }

    if (window.innerWidth >= 1400) {
      $(document).ready(function() {
        $('.news-listing-item-wrp').slice(0, 8).show();
        if ($('.news-listing-item-wrp:hidden').length != 0) {
            $('#loadMore').show();
        }
        $('#loadMore').on('click', function(e) {
            e.preventDefault();
            $('.news-listing-item-wrp:hidden').slice(0, 8).slideDown();
            if ($('.news-listing-item-wrp:hidden').length == 0) {
                $('#loadMore').text('No More to view').fadeOut('slow');
            }
        });
      })
    }

    $('.member-posts').on('click', '.member-content-wrp-outter', function(e){
      e.preventDefault();
      $(this).siblings().removeClass('content-expanded');
      $(this).toggleClass('content-expanded');
    });

    // Team page filter function
    $('#filter-form input[type="checkbox"]').click(function(e) {
      e.preventDefault();
      $('#filter-form input[type="checkbox"]').not(this).prop("checked", false);
      $('#filter-by-team-category').trigger('click');
    });

    jQuery(".form-dropdown").select2({
      dropdownParent: $('.select2-wrapper'),
    });

    jQuery("a[data-rel^=lightcase]").lightcase({
      slideshowAutoStart: false,
    });

    $('header .mobile-menu').click(function(){
      $('body').toggleClass('mobile-menu-active');
    });

    checkFormLastChild();
    twoColWaveBg();

    let $gallerySection = $('.gallery-section .gallery-wrapper');

    if ($gallerySection.length > 0) {
      $gallerySection.flickity({
        contain: false,
        pageDots: true,
        prevNextButtons: true,
        adaptiveHeight: false,
        wrapAround: true,
        cellAlign: 'center',
      });
    }

    let $imageGallery = $('.image-gallery .gallery-wrapper');

    if ($imageGallery.length > 0) {
      $imageGallery.flickity({
        contain: true,
        pageDots: true,
        prevNextButtons: true,
        adaptiveHeight: false,
        wrapAround: true,
        cellAlign: 'center',
      });
    }
  },
};

$(window).on('load', function () {
  $('.gallery-section .gallery-wrapper').flickity(
    'resize'
  );
});

function getCurrentScroll() {
  return window.pageYOffset || document.documentElement.scrollTop;
}

$(window).scroll(function () {
  var scroll = getCurrentScroll();
  if (scroll >= shrinkHeader) {
    $('body, .header-container').addClass('sticky-header');
  } else {
    $('body, .header-container').removeClass('sticky-header');
  }
});

function checkFormLastChild() {
  if ($(".form-section").is(":last-child")) {
    $(".form-section").addClass("last-section")
    $(".footer").addClass("form-above")
  }
}

function twoColWaveBg() {
  var h = $('.waving-dots-wrapper').width();
  for(var i = 0; i < h; i++){
      var flagElement = $("<div class='flag-element'>");
      flagElement.css('background-position', -i + "px 0");
      flagElement.css('-webkit-animation-delay', i * 10 + 'ms');
      flagElement.css('-moz-animation-delay', i * 10 + 'ms');
      flagElement.css('-ms-animation-delay', i * 10 + 'ms');
      flagElement.css('animation-delay', i * 10 + 'ms');
      $('.waving-dots-wrapper').append(flagElement);
  }
}
